import React from "react";
import NewsList from "../components/NewsList";

export default function Home() {
    return (
        <div>
            <NewsList />
        </div>
    );
}
